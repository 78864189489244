import Jimp from "jimp";

export const getJIMPImages = async (imageList, flip = false) => {
  let images = await Promise.all(
    imageList.map(i => Jimp.read(`data:image/jpeg;base64,${i.data}`))
  );
  if (flip) {
    images.map(img => img.flip(false, true));
  }
  return images;
};

export const median = async images => {
  const medianImage = new Jimp(images[0].bitmap.width, images[0].bitmap.height);

  if (images.length === 1)
  {
    return images[0];
  }
  
  images[0].scan(
    0,
    0,
    images[0].bitmap.width,
    images[0].bitmap.height,
    (x, y) => {
      const pixels = images
        .map(i => {
          const pixel = i.getPixelColor(x, y);
          const { r, g, b } = Jimp.intToRGBA(pixel);
          return { pixel, sum: r + g + b };
        })
        .sort((a, b) => a.sum - b.sum);
      const medianPixel = pixels[Math.floor(pixels.length / 2)].pixel;
      medianImage.setPixelColor(medianPixel, x, y);
    }
  );

  return medianImage;
};

export const extractOld = async (a, b) => {
  const c = new Jimp(a.bitmap.width, a.bitmap.height);
  a.scan(0, 0, a.bitmap.width, a.bitmap.height, (x, y) => {
    const aa = Jimp.intToRGBA(a.getPixelColor(x, y));
    const bb = Jimp.intToRGBA(b.getPixelColor(x, y));
    try {
      const r = Math.max(Math.min(aa.r - bb.r, 255), 0);
      const g = Math.max(Math.min(aa.g - bb.g, 255), 0);
      const b = Math.max(Math.min(aa.b - bb.b, 255), 0);
      c.setPixelColor(Jimp.rgbaToInt(r, g, b, aa.a), x, y);
    } catch (error) {
      console.log(aa, bb);
    }
  });
  return await c;
};

export const getBase64 = async img => {
  if (!img) return null;
  return await img.getBase64Async(Jimp.MIME_JPEG);
};

function getImageFromJimp(jimage)
{
  var image = new Image();
  
  var result = new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      reject();
    };
    image.src = `data:image/jpeg;base64,${jimage.data}`;    
  });
  
  return result;
}

export async function extract(imageA, imageB) {
  imageA = await getImageFromJimp(imageA);
  imageB = await getImageFromJimp(imageB);
  
  const vertexShaderSource = `
    #ifdef GL_ES
    precision highp float;
    #endif
    
    attribute vec3 aVertexPosition;
    attribute vec2 aTextureCoord;
    varying vec3 vPosition;
    varying vec2 vTextureCoord;
    
    void main(void) {
      vPosition = aVertexPosition;
      vTextureCoord = aTextureCoord;
      gl_Position = vec4(vPosition,1.0);
    }
  `;

  const fragmentShaderSource = `
    #ifdef GL_ES
    precision highp float;
    #endif
    
    uniform sampler2D uSamplerA;
    uniform sampler2D uSamplerB;
    varying vec3 vPosition;
    varying vec2 vTextureCoord;
    
    void main(void) {
      vec3 vPos = vPosition;
      
      vec2 vMapping = (vPos.xy+1.0)/2.0;
      
      vec4 textureA = texture2D(uSamplerA, vMapping);
      vec4 textureB = texture2D(uSamplerB, vMapping);
      
      vec4 diff = textureA - textureB;
      diff.w = 1.0;
      
      gl_FragColor = diff;
    }
  `;
  
  var canvas = document.createElement('canvas');
  canvas.width = imageA.width;
  canvas.height = imageA.height;
  canvas.style.visibility = 'hidden';
  document.body.appendChild(canvas);
  canvas.style.visibility = 'hidden';
  
  var model = {
    vertex :[
      -1.0, -1.0, 0.0,
       1.0, -1.0, 0.0,
       1.0,  1.0, 0.0,
      -1.0,  1.0, 0.0
    ],
    indices :[
      0, 1, 2,
      0, 2, 3,
      2, 1, 0,
      3, 2, 0
    ],
    textureCoords : [
      0.0, 0.0,
      1.0, 0.0,
      1.0, 1.0,
      0.0, 1.0
    ]
  };

  var vertexBuffer, indexBuffer, textureBuffer;

  function getGLContext(canvas) {
    if(canvas == null) {
      throw new Error("there is no canvas on this page");
    }
    
    var names = ["webgl", "experimental-webgl", "webkit-3d", "moz-webgl"];
    for (var i = 0; i < names.length; ++i) {
      var gl;
      try {
        gl = canvas.getContext(names[i], { preserveDrawingBuffer: true });
      } catch(e) {
        continue;
      }
      if (gl) return gl;
    }
    
    throw new Error("WebGL is not supported!");
  }
  
  function compileShader(gl, vertexSrc, fragmentSrc) {
    var vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vertexSrc);
    gl.compileShader(vertexShader);
    
    _checkCompile(vertexShader);
    
    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentSrc);
    gl.compileShader(fragmentShader);
    
    _checkCompile(fragmentShader);
    
    var program = gl.createProgram();
    
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    
    gl.linkProgram(program);
    
    return program;
    
    function _checkCompile(shader){
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        throw new Error(gl.getShaderInfoLog(shader));
      }
    }
  }
  
  function createBuffers() {
    vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(model.vertex), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(model.indices), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);

    textureBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(model.textureCoords), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

  }

  var gl = getGLContext(canvas);
  
  var program = compileShader(gl, vertexShaderSource, fragmentShaderSource)
  gl.useProgram(program);
  
  var aVertexPosition = gl.getAttribLocation(program, "aVertexPosition");
  var aTextureCoord = gl.getAttribLocation(program, "aTextureCoord");
  var uSamplerA = gl.getUniformLocation(program, "uSamplerA");
  var uSamplerB = gl.getUniformLocation(program, "uSamplerB");
  
  createBuffers();

  function loadImage(gl, img) {
    var texture = gl.createTexture();

    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR); //gl.NEAREST is also allowed, instead of gl.LINEAR, as neither mipmap.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE); //Prevents s-coordinate wrapping (repeating).
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE); //Prevents t-coordinate wrapping (repeating).
    //gl.generateMipmap(gl.TEXTURE_2D);
    gl.bindTexture(gl.TEXTURE_2D, null);

    return texture;
  }
  
  var textureA = loadImage(gl, imageA);
  var textureB = loadImage(gl, imageB);
  
  gl.clearColor(0.0, 0.0, 0.0, 1.0);
  gl.enable(gl.DEPTH_TEST);

  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  gl.enableVertexAttribArray(aVertexPosition);

  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.vertexAttribPointer(aVertexPosition, 3, gl.FLOAT, false, 0, 0);

  gl.enableVertexAttribArray(aTextureCoord);

  gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
  gl.vertexAttribPointer(aTextureCoord, 2, gl.FLOAT, false, 0, 0);

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, textureA);
  gl.uniform1i(uSamplerA, 0);
  
  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, textureB);
  gl.uniform1i(uSamplerB, 1);
  
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
  gl.drawElements(gl.TRIANGLES, model.indices.length, gl.UNSIGNED_SHORT, 0);
  
  const result = canvas.toDataURL()
  canvas.remove();
  
  return Jimp.read(result);
}
