<template>
  <v-container>
    <h1>{{ $t("list-of-records") }}</h1>
    <box-status />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-text-field
              type="number"
              v-model="year"
              single-line
              hide-details
              v-if="!search"
              v-on:change="sanitizeYear(true)"
              :prefix="`${$t('year')}: `"
            />
          </v-col>
          <v-col cols="auto">
            <v-text-field
              type="number"
              v-model="limit"
              :prefix="`${$t('items-per-page')}: `"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :loading="loading"
        :item-class="itemRowBackground"
        hide-default-footer
        sort-by="timestamp"
        sort-desc
        class="main-table"
        @click:row="
          item =>
            $router.push({
              name: 'Datasheet',
              params: { recordId: item.PatientID }
            })
        "
      >
        <!-- :search="search" -->
        <template v-slot:top>
          <v-toolbar flat rounded>
            <v-toolbar-title>
              {{ $t("list-of-records") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
							v-if="$store.state.user.type!='tech'"
              color="primary"
              dark
              class="mb-2"
              @click="createRecord"
              depressed
            >
              {{ $t("new-item") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.dateTime="{ item }">
          {{ new Date(item.dateTime).toLocaleString($i18n.locale) }}
        </template>
        <template v-slot:item.sendTo="{ item }">
          <v-chip v-if="item.sendTo">
            {{ $t(item.sendTo) }}
          </v-chip>
        </template>
        <template v-slot:item.address="{ item }">
          <span v-html="formatAddress(item.address)"></span>
        </template>

        <template #footer>
          <v-container
            fluid
            class="d-flex"
            v-if="(records && records.LastEvaluatedKey) || pagination"
          >
            <v-btn
              depressed
              :disabled="!hasPrevious"
              :text="!hasPrevious"
              @click="query(0, 'fwd')"
            >
              {{ $t("previous") }}
            </v-btn>
            <v-spacer />
            <v-btn
              depressed
              :disabled="!hasNext"
              :text="!hasNext"
              @click="query(0, 'bwd')"
            >
              {{ $t("next") }}
            </v-btn>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BoxStatus from "../components/BoxStatus.vue";

export default {
  name: "Home",
  components: { BoxStatus },
  data() {
    return {
      search: null,
      year: new Date().getFullYear(),
      limit: 50,
      loading: true,
      options: { itemsPerPage: Infinity },
      queryDebounce: null,
      pagination: null
    };
  },
  mounted() {
    this.query();
  },
  computed: {
    ...mapState(["records"]),
    headers() {
      return [
        {
          text: this.$t("id"),
          align: "start",
          value: "PatientID"
        },
        { text: this.$t("name"), value: "name" },
        { text: "E", value: "e" },
        { text: "V", value: "v" },
        { text: this.$t("registered-at"), value: "dateTime" },
        { text: this.$t("address"), value: "address", sortable: false },
        { text: this.$t("send-to"), value: "sendTo" },
        { text: this.$t("comment"), value: "comment", sortable: false },
      ];
    },
    items() {
      if (!this.records) return [];
      return this.records.Items;
    },
    hasPrevious() {
      if (!this.pagination) return false;
      if (!this.pagination.ScanForward) return true;
      return !!this.records.LastEvaluatedKey;
    },
    hasNext() {
      if (!this.pagination) return !!this.records.LastEvaluatedKey;
      if (!this.pagination.ScanForward) return !!this.records.LastEvaluatedKey;
      return true;
    }
  },
  methods: {
    ...mapActions(["fetchRecords"]),
    async query(debounce = 0, pagination = null /* "fwd" || "bwd" || null */) {
      if (this.queryDebounce) window.clearTimeout(this.queryDebounce);
      if (debounce) {
        this.queryDebounce = window.setTimeout(() => this.query(), debounce);
        return;
      }

      this.loading = true;
      const params = {};
      
      if (this.$store.state.user.type != 'tech' || !this.search)
      {
        params.sortYear = parseInt(this.year);
      }
      
      if (this.$store.state.user.type != 'tech')
      {
        params.SearchUserID = this.$store.state.user.UserID;
      }
      
      if (this.limit) params.Limit = parseInt(this.limit);
      if (this.search) params.NameSearchString = this.search;

      if (pagination) {
        params.ScanForward = pagination === "fwd";
        const startKey = this.records.LastEvaluatedKey
          ? btoa(JSON.stringify(this.records.LastEvaluatedKey))
          : this.pagination.StartKey;
        params.StartKey = startKey;
        this.pagination = params;
      } else {
        this.pagination = null;
      }

      await this.fetchRecords(params);
      this.loading = false;
    },
    formatAddress(address) {
      if (!address) return "";
      const items = [];
      if (address.streetAddress) items.push(address.streetAddress);
      if (address.zipCode) items.push(address.zipCode);
      if (address.city) items.push(address.city);
      if (address.country) items.push(address.country);
      return items.join(", ");
    },
    async createRecord() {
      const newRecord = await this.$store.dispatch("createRecord");
      console.log(newRecord);
      this.$router.push({
        name: "Datasheet",
        params: { recordId: newRecord.PatientID }
      });
    },
    itemRowBackground(item) {
      var hasNew = false;
      var hasInProgress = false;
      var hasShipped = false;
      var hasOrders = false;
      
      if (item.orders)
      {
        //"order-status-new"
        for (var i = 0; i < item.orders.length; i++)
        {
          hasOrders = true;
          if (item.orders[i].status == "order-status-new")
          {
            hasNew = true;
          }
          
          if (item.orders[i].status == "order-status-progress")
          {
            hasInProgress = true;
          }
          
          if (item.orders[i].status == "order-status-shipped")
          {
            hasShipped = true;
          }
        }
      }
      
      if (hasNew) {
        return "itemWithNewOrder";
      }
      if (hasInProgress) {
        return "itemWithNewInProgressOrder";
      }
      if (hasShipped) {
        return "itemWithShipped";
      }
      if (!hasOrders)
      {
        return "itemWithoutOrder";
      }
    },
    sanitizeYear(doChange) {
      if (this.year < 1990)
      {
        if (doChange)
        {
          this.year = 1990;
        }
        
        return false;
      }
      
      if (this.year > (new Date().getFullYear()+1))
      {
        if (doChange)
        {
          this.year = new Date().getFullYear();
        }
        
        return false;
      }
      
      return true;
    }
  },
  watch: {
    year() {
      if (this.sanitizeYear(false))
      {
        this.query(200);
      }
    },
    limit() {
      this.query(200);
    },
    search() {
      this.query(200);
    }
  }
};
</script>
<style lang="scss">
.main-table tr {
  cursor: pointer;
}
.itemWithNewOrder {
  background-color: #FFA0A0;
}
.itemWithNewInProgressOrder {
  background-color: #FFFFA0;
}
.itemWithShipped {
  background-color: #A0FFA0;
}
.itemWithoutOrder {
  background-color: #A0FFFF;
}
</style>
