<template>
  <v-app>
    <v-app-bar app color="primary" dark clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title>
        <router-link
          to="/"
          class="white--text text-decoration-none font-weight-bold"
        >
          {{ $t("app-title") }}
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        text
        :to="logout"
        v-if="$store.state.user && $store.state.user.UserID"
      >
        {{ $t("logout") }}
      </v-btn>
      <v-btn text @click="changeLocale">{{ $i18n.locale }}</v-btn>
    </v-app-bar>

    <v-navigation-drawer
      permanent
      app
      clipped
      :mini-variant="!drawer"
      :expand-on-hover="!drawer"
    >
      <v-list>
        <v-list-item class="px-2">
            <v-img
              :src="require('@/assets/logo_spm_small.png')"
              max-width="30%"
              max-height="30%"
            />
          <v-list-item-content>
            <v-list-item-title class="mainTitleStyle">
              {{ $t("app-title-short") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("home") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="$route.params.recordId">
          <v-divider class="mb-1" />

          <v-list-item
            v-for="{ name, meta: { icon } } in showRecordItems"
            :key="name"
            link
            :to="{ name }"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t(name.toLowerCase())
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="$store.state.user && $store.state.user.type === 'tech'">
          <v-divider class="mb-1" />

          <v-list-item
            v-for="{ name, icon } in adminSidebarItems"
            :key="name"
            link
            :to="{ name }"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t(name.toLowerCase())
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template #append>
        <v-list dense nav>
          <v-list-item
            link
            :to="logout"
            v-if="$store.state.user && $store.state.user.UserID"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-overlay :value="true" opacity=".8" class="d-md-none">
        <div class="headline white--text pa-3 text-center">
          {{ $t("screen-size-not-supported") }}
        </div>
      </v-overlay>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import saveState from "vue-save-state";
import { recordRoutes } from "@/router";

export default {
  name: "App",
  mixins: [saveState],
  data: () => ({
    drawer: true
  }),
  computed: {
    showRecordItems() {
      return recordRoutes.filter(
        r =>
          this.$store.state.user &&
          r.meta.show.includes(this.$store.state.user.type)
      );
    },
    adminSidebarItems() {
      return [
        { name: "Worksheet", icon: "mdi-playlist-edit" },
        { name: "OptionEditor", icon: "mdi-playlist-edit" },
        { name: "CoverEditor", icon: "mdi-image-edit" },
        { name: "Stats", icon: "mdi-chart-line" }
      ];
    },
    logout() {
      return {
        name: "Logout",
        query: {
          redirect: this.$route.path
        }
      };
    }
  },
  methods: {
    changeLocale() {
      const ls = this.$i18n.availableLocales;
      console.log(ls);
      this.$vuetify.lang.current = this.$i18n.locale =
        ls[(ls.indexOf(this.$i18n.locale) + 1) % ls.length];
      window.localStorage.setItem("lang", this.$i18n.locale);
      window.document.title = this.$t("app-title");
    },
    getSaveStateConfig() {
      return {
        cacheKey: "app",
        saveProperties: ["drawer"]
      };
    }
  }
};
</script>

<style>
.mainTitleStyle {
  font-size: 20px;
  text-align: center;
  color: #007000;
  font-family: Cambria;
}

</style>
