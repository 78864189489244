import axios from "axios";
import https from "https";
import store from "./store";
import Jimp from "jimp";
const shajs = require("sha.js");

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
https.globalAgent.options = { rejectUnauthorized: false };
console.log(https.globalAgent);

// cloud api

const cloudApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
  headers: { "x-api-key": process.env.VUE_APP_API_KEY }
});

const params = async config => {
  const UserID = (store.state.user && store.state.user.UserID) || null;
  const UserSecret = (store.state.user && store.state.user.UserSecret) || null;
  const RandomSalt = Math.round(Math.random() * 1e16).toString(36);
  const ServerTime = await store.dispatch("getServerTime");

  let rawStr = "";

  if (config.params) {
    const keys = Object.keys(config.params);
    keys.sort();
    rawStr += keys.reduce(
      (accStr, key) => accStr + `${key}=${config.params[key]}&`,
      rawStr
    );
  }

  if (config.data) {
    console.log("using data:", config.data);
    rawStr += JSON.stringify(config.data);
  }

  rawStr += UserSecret;
  rawStr += RandomSalt;
  rawStr += ServerTime;

  const HexHash = shajs("sha256")
    .update(rawStr)
    .digest("hex");

  return { ...(config.params || {}), UserID, HexHash, RandomSalt, ServerTime };
};

cloudApi.interceptors.request.use(
  async config => {
    if (config.url !== "get-time") {
      document.getElementById('app').style.cursor = "wait";
      const p = await params(config);
      config.params = p;
    }
    return config;
  },
  error => {
    document.getElementById('app').style.cursor = "default";
    return Promise.reject(error);
  }
);

cloudApi.interceptors.response.use(
  response => {
    document.getElementById('app').style.cursor = "default";
    return response;
  },
  error => {
    document.getElementById('app').style.cursor = "default";
    return Promise.reject(error);
  }
);

// box api

const boxApi = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
  timeout: 5000,
  retryDelay : 1000,
  retry: 3,
  headers: { "x-api-key": process.env.VUE_APP_BOX_API_KEY }
});

const manageStatus = fail => {
  window.clearTimeout(store.state.boxStatus.timer);
  const timer = window.setTimeout(() => {
    if (store.state.user && store.state.user.type === "user") {
      store.dispatch("getBoxCheck");
    }
  }, 5000);
  store.commit("setBoxStatus", { timer, fail });
};

boxApi.interceptors.response.use(
  response => {
    manageStatus(false);
    return response;
  },
  error => {
    const { config, message } = error;
    if (!config || config.retry === 0) {
      manageStatus(true);
      return Promise.reject(error);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
      manageStatus(true);
      return Promise.reject(error);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 5000);
    });
    return delayRetryRequest.then(() => boxApi(config));
  }
);

boxApi.getImage = async function(camera, retry=5) {
  var result;
  
  try {
    result = await boxApi.get(`/snapshot_${camera}`);
//     console.log("RECV:", result);
    await Jimp.read(`data:image/jpeg;base64,${result.data}`);
  } catch (except)
  {
    console.error("Capture error, retry", retry);
    if (retry > 0)
    {
      return await boxApi.getImage(camera, retry-1);
    }
    else
    {
      throw except;
    }
  }
  
  return result;
}

export { cloudApi, boxApi };
