
export const matchPelottaWithSize = (pelottaName, pelottaSize, shoeSize) => {
  if (pelottaName == "hosszem")
  {
    if (shoeSize < 35 && pelottaSize == 2)
    {
      return true;
    }
    else if (35 <= shoeSize && shoeSize <= 39 && pelottaSize == 3)
    {
      return true;
    }
    else if (40 <= shoeSize && shoeSize <= 42 && pelottaSize == 4)
    {
      return true;
    }
    else if (43 <= shoeSize && shoeSize <= 45 && pelottaSize == 5)
    {
      return true;
    }
    else if (46 <= shoeSize && shoeSize <= 48 && pelottaSize == 6)
    {
      return true;
    }
  }
  
  if (pelottaName == "vese")
  {
    if (shoeSize <= 39 && pelottaSize == 0)
    {
      return true;
    }
    else if (40 <= shoeSize && shoeSize <= 42 && pelottaSize == 1)
    {
      return true;
    }
    else if (43 <= shoeSize && shoeSize <= 44 && pelottaSize == 2)
    {
      return true;
    }
    else if (45 <= shoeSize && shoeSize <= 46 && pelottaSize == 3)
    {
      return true;
    }
    else if (47 <= shoeSize && shoeSize <= 48 && pelottaSize == 4)
    {
      return true;
    }
  }
  
  if (pelottaName == "csepp")
  {
    if (shoeSize <= 28 && pelottaSize == "gy0")
    {
      return true;
    }
    else if (29 <= shoeSize && shoeSize <= 32 && pelottaSize == "gy1")
    {
      return true;
    }
    else if (33 <= shoeSize && shoeSize <= 38 && pelottaSize == "f1")
    {
      return true;
    }
    else if (39 <= shoeSize && shoeSize <= 43 && pelottaSize == "f2")
    {
      return true;
    }
    else if (44 <= shoeSize && shoeSize <= 45 && pelottaSize == "f3")
    {
      return true;
    }
    else if (46 <= shoeSize && shoeSize <= 49 && pelottaSize == "f4")
    {
      return true;
    }
  }
  
//   console.log(pelottaName, pelottaSize, shoeSize);
  return false;
};

const sizeTextNumbers = [
  ["", 0],
  ["gy", 0],
  ["f", 100],
  ["fpuha", 100],
  ["kicsi", 0],
  ["nagy", 100]
];


const parsePelottaSize = (x) => {
  var textPart = ""
  var sizeNumber = ""
  
  for (var i = 0; i < x.length; i++)
  {
    if (x[i].match(/[0-9]/))
    {
      sizeNumber += x[i];
    }
    else
    {
      textPart += x[i];
    }
  }
  
  const res = sizeTextNumbers.find(size => size[0] === textPart);
  
  if (sizeNumber === "")
  {
    sizeNumber = 0;
  }
  else
  {
    sizeNumber = parseInt(sizeNumber);
  }
  
  return res[1] + sizeNumber;
};

const convertPelottaSizeToInteger = (x) => {
  const y = parseInt(x.size);
  
  if (isNaN(y))
  {
    return parsePelottaSize(x.size);
  }
  else
  {
    return y;
  }
}

export const comparePelottaSizes = (a, b) => {
  const aN = convertPelottaSizeToInteger(a);
  const bN = convertPelottaSizeToInteger(b);
  
//   console.log(aN, bN, a.size, b.size);
  
  if (aN < bN)
  {
    return -1;
  }
  else if (aN > bN)
  {
    return 1;
  }
  else
  {
    return 0;
  }
}

