import Vue from "vue";
import Vuex from "vuex";
import { cloudApi, boxApi } from "../api";
import moment from "moment";
import Jimp from "jimp";
import optionsModule from "./optionsModule";
import {
  extract,
  getBase64,
  getJIMPImages
} from "../utils/processSnapshot";
import { comparePelottaSizes } from "@/utils/pelottaHelper";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serverTime: {
      value: null,
      queriedAt: null
    },
    pcc: 0,
    records: null,
    coverImages: null,
    user: window.localStorage.getItem("user")
      ? JSON.parse(window.localStorage.getItem("user"))
      : null,
    pelottas: [],
    boxStatus: {
      timer: null,
      queriedAt: null,
      fail: false,
      ping: null
    }
  },
  getters: {
    pelottaGroups: state => {
      const groupsObject = state.pelottas.reduce((groups, p) => {
        if (!groups[p.name]) groups[p.name] = { name: p.name, importanceIndex : p.importanceIndex, isBase : p.isBase, Items: [] };
        groups[p.name].Items.push({
          ...p,
        });
        return groups;
      }, {});
      const groupsArray = Object.values(groupsObject);
      groupsArray.map(group => group.Items.sort(comparePelottaSizes));
      return groupsArray;
    }
  },
  mutations: {
    setServerTime: (state, value) =>
      (state.serverTime = { value, queriedAt: new Date().getTime() }),
    setRecords: (state, value) => (state.records = value),
    setCoverImages: (state, value) => (state.coverImages = value),
    removeCoverImage: (state, value) => {
      const { Images, Captions } = state.coverImages;
      delete Images[value];
      delete Captions[value];
      state.coverImages = { Images, Captions };
    },
    setUser: (state, value) => {
      console.log("setUSer:", value);
      let boxUrl = null;
      let cachedUser = null;
      switch (value && value.type) {
        case "user":
          console.log("user", value.boxUrl);
          boxUrl = value.boxUrl;
          cachedUser = { type: value.type, boxUrl: value.boxUrl, HumanUserID: value.HumanUserID };
          break;
        case "tech":
          console.log("tech");
          cachedUser = value;
          break;
        default:
          break;
      }
      console.log("store user:", JSON.stringify(cachedUser));
      window.localStorage.setItem("user", JSON.stringify(cachedUser));
      boxApi.defaults.baseURL = boxUrl;
      state.user = value;
    },
    setPelottas: (state, value) => (state.pelottas = value),
    setBoxStatus: (state, value) =>
      (state.boxStatus = {
        ...state.boxStatus,
        ...value,
        queriedAt: new Date().getTime()
      })
  },
  actions: {
    async getServerTime({ commit, state }) {
      if (
        !state.serverTime.value ||
        new Date().getTime() - state.serverTime.queriedAt > 30 * 1000
      ) {
        const serverTime = (await cloudApi.get("get-time")).data.time;
        commit("setServerTime", serverTime);
        return serverTime;
      }
      return state.serverTime.value;
    },
    async fetchCoverImageList({ commit }) {
      const coverImages = (await cloudApi.get("list-image", {})).data;
      commit("setCoverImages", coverImages);
    },
    async deleteCoverImage({ commit }, coverImageName) {
      await cloudApi
        .post("delete-image", { FileName: coverImageName })
        .then(() => commit("removeCoverImage", coverImageName));
    },
    async fetchRecords({ commit }, params = {}) {
      console.log(params);
      const records = (await cloudApi.get("query", { params })).data;
      commit("setRecords", records);
      return records;
    },
    async quickSaveRecord({ commit }, data) {
      commit;
      console.log("saving:", data);
      const result = (await cloudApi.post("update", data)).data;
      return result;
    },
    async updateRecord({ commit }, data) {
      console.log("updateRecord", data);
      const result = (await cloudApi.post("update", data)).data;
      commit("setRecords", { Items: [result] });
      return result;
    },
    async createRecord({ commit, state }) {
      console.log(state);
      moment;
      const data = {
        address: {},
        painMap: {},
        HumanUserID: state.user.HumanUserID,
        HumanUserLongName: state.user.HumanUserLongName
        // dateTime: moment().toISOString(true),
        // sortYear: new Date().getFullYear(),
        // timestamp: Math.round(new Date().getTime() / 1000)
      };
      console.log(data);
      const result = (await cloudApi.post("insert", data)).data;
      commit("setRecords", { Items: [result] });
      return result;
    },
    async getBoxCheck({ commit }) {
      const start = new Date().getTime();
      const check = (await boxApi.get("check")).data;
      const ping = new Date().getTime() - start;
      commit("setBoxStatus", { ping });
      return check;
    },
    async getBoxCredentials({ commit, state }, save = true) {
      console.log("getcred");
      
      const cred = (await boxApi.get("get_credentials")).data;
      if (save)
      {
        var HumanUserID = state.user.HumanUserID;
        var password = state.user.Password;

        commit("setUser", {
          type: "user",
          boxUrl: boxApi.defaults.baseURL,
          HumanUserID : HumanUserID,
          Password : password,
          ...cred
        });

        var response = await cloudApi.get("check-user", {params: {Password : password, UserName : HumanUserID}})
        
        if (response.data.result != "OK")
        {
          throw new Error(response.data.result);
        }
        
        commit("setUser", {
          type: "user",
          boxUrl: boxApi.defaults.baseURL,
          HumanUserID : HumanUserID,
          HumanUserLongName: response.data.HumanUserLongName,
          Role : response.data.Role,
          Password : password,
          ...cred
        });
      }
      return cred;
    },
    async getBoxWeight() {
      const result = (await boxApi.get("/weight")).data.weight;
      return Math.round(result);
    },
    async getBoxPreview(store, camera) {
      var img;
      var ww = store.state.pcc++;
      
      console.log("getBoxPreview", (Date.now()+0.0)/1000.0, ww);
      
      try {
        const result = await boxApi.get(`/preview_${camera}`);
        img = await getBase64((await getJIMPImages([result], camera === "feet"))[0]);
      } catch (except)
      {
        console.log("getBoxPreview done, err", (Date.now()+0.0)/1000.0, ww);
        await new Promise(resolve => setTimeout(resolve, 2000));
        throw except;
      }
      
      console.log("getBoxPreview done", (Date.now()+0.0)/1000.0, ww);
      
      return img;
    },
    async getBoxSnapshot(store, camera) {
      const hash = new Date().getTime().toString(36);
      console.time(`${hash} ${camera} capture snapshot`);
      
      let result = null;
      try {
      if (camera === "feet") {
          // FEET
          await boxApi.get("/light", { params: { pwm: 255 } });
          const highLight = await boxApi.getImage(camera);
          
          await boxApi.get("/light", { params: { pwm: 0 } });
          const lowLight = await boxApi.getImage(camera);
          
          result = await extract(highLight, lowLight);
      } else {
          // HEELS
          result = await boxApi.getImage(camera);
          result = await Jimp.read(`data:image/jpeg;base64,${result.data}`);
        }
      } catch(except)
      {
        console.error("Exception from capture!");
        console.error(except);
      }
      
      await boxApi.get("/light", { params: { pwm: 64 } });
      await boxApi.get("/light", { params: { pwm: 64 } });
      console.timeEnd(`${hash} ${camera} capture snapshot`);
      return getBase64(result);
    },
    async upload({ dispatch }, { camera, FileName, PatientID, data }) {
      const result = (
        await cloudApi.post("/upload", { FileName, PatientID, data })
      ).data;
      const updatedRecord = { PatientID };
      updatedRecord[`${camera}Image`] = FileName;
      const updated = await dispatch("updateRecord", updatedRecord);
      return { result, updated };
    },
    async download(store, { FileName, PatientID }) {
      const result = (
        await cloudApi.get("/download", { params: { FileName, PatientID } })
      ).data;
      return result;
    },
    async listPelottas({ dispatch, commit }) {
      console.time("pelottas");
      const result = (await cloudApi.get("/list-pelotta")).data;
      const requests = result.PelottaIDs.map(async ({ PelottaID }) =>
        dispatch("getPelotta", PelottaID)
      );
      const pelottasData = await Promise.all(requests);
      commit("setPelottas", pelottasData);
      console.timeEnd("pelottas");
      return pelottasData;
    },
    async listUsers() {
      const result = (await cloudApi.get("/get-user-list")).data;
      return result;
    },
    async checkTechRole(store, tech) {
      var response = await cloudApi.get("check-user", {params: {Password : tech.UserSecret, UserName : tech.UserID}})
      
      if (response.data.result != "OK")
      {
        throw new Error("wrong-credential");
      }
      
      if (response.data.Role != "editor")
      {
        throw new Error("wrong-role");
      }
      
      return true;
    },
    async getPelotta(store, PelottaID) {
      const result = (
        await cloudApi.get("/get-pelotta", { params: { PelottaID } })
      ).data;
      return result;
    },
    async pdfReport(store, PatientID) {
      const result = (
        await cloudApi.get("/pdf-report", { params: { PatientID } })
      ).data;
      return result;
    },
    async pdfGDPR(store, PatientID) {
      const result = (
        await cloudApi.get("/get-gdpr-pdf", { params: { PatientID } })
      ).data;
      return result;
    },
    async pdfStats(store, statParams) {
      const result = (
        await cloudApi.get("/get-stats-pdf", { params: statParams })
      ).data;
      return result;
    },
    async csvStats(store, statParams) {
      const result = (
        await cloudApi.get("/get-stats-csv", { params: statParams })
      ).data;
      return result;
    },
    async pdfWorksheet(store, PatientIDs) {
      const result = (
        await cloudApi.get("/pdf-worksheet", { params: { PatientIDs } })
      ).data;
      return result;
    },
    async pdfDesign(store, { PatientID, OrderIndex }) {
      //console.log("PatientID:", PatientID, "OrderIndex:", OrderIndex)
      if (OrderIndex === undefined)
      {
        const result = (
          await cloudApi.get("/pdf-design", { params: { PatientID } })
        ).data;
        return result;
      }
      else
      {
        const result = (
          await cloudApi.get("/pdf-design", { params: { PatientID, OrderIndex } })
        ).data;
        return result;
      }
    },
    async sendEmail(store, PatientID) {
      const result = (await cloudApi.post("/send-email", { PatientID })).data;
      return result;
    }
  },
  modules: {
    optionsModule
  }
});
