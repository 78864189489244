import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import $tu from "./utils/$tu";
import VuetifyConfirm from "vuetify-confirm";
import VueKonva from "vue-konva";
import "./registerServiceWorker";

Vue.use(VueKonva);

Vue.config.productionTip = false;

Vue.use(VuetifyConfirm, {
  vuetify,
  color: "primary",
  title: "Warning",
  width: 350,
  property: "$confirm",
  persistent: false
});

Vue.prototype.$tu = $tu;

store.dispatch("getServerTime");

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
