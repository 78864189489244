var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("list-of-records")))]),_c('box-status'),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.search)?_c('v-text-field',{attrs:{"type":"number","single-line":"","hide-details":"","prefix":((_vm.$t('year')) + ": ")},on:{"change":function($event){return _vm.sanitizeYear(true)}},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"type":"number","prefix":((_vm.$t('items-per-page')) + ": "),"single-line":"","hide-details":""},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})],1)],1)],1),_c('v-data-table',{staticClass:"main-table",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"item-class":_vm.itemRowBackground,"hide-default-footer":"","sort-by":"timestamp","sort-desc":""},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item) { return _vm.$router.push({
              name: 'Datasheet',
              params: { recordId: item.PatientID }
            }); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","rounded":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("list-of-records"))+" ")]),_c('v-spacer'),(_vm.$store.state.user.type!='tech')?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.createRecord}},[_vm._v(" "+_vm._s(_vm.$t("new-item"))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.dateTime",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.dateTime).toLocaleString(_vm.$i18n.locale))+" ")]}},{key:"item.sendTo",fn:function(ref){
            var item = ref.item;
return [(item.sendTo)?_c('v-chip',[_vm._v(" "+_vm._s(_vm.$t(item.sendTo))+" ")]):_vm._e()]}},{key:"item.address",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatAddress(item.address))}})]}},{key:"footer",fn:function(){return [((_vm.records && _vm.records.LastEvaluatedKey) || _vm.pagination)?_c('v-container',{staticClass:"d-flex",attrs:{"fluid":""}},[_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.hasPrevious,"text":!_vm.hasPrevious},on:{"click":function($event){return _vm.query(0, 'fwd')}}},[_vm._v(" "+_vm._s(_vm.$t("previous"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.hasNext,"text":!_vm.hasNext},on:{"click":function($event){return _vm.query(0, 'bwd')}}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")])],1):_vm._e()]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }