import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import i18n from "../i18n";
import en from "vuetify/es5/locale/en";
import hu from "vuetify/es5/locale/hu";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, hu },
    current: i18n.locale
  },
  theme: {
    themes: {
      light: {
        primary: colors.green.darken3,
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.indigo.base // #3F51B5
      }
    }
  }
});
