<template>
  <v-alert
    v-if="user.type === 'user'"
    border="left"
    colored-border
    :type="type"
    elevation="1"
    class="py-2"
  >
    <h4>{{ $t("box-status") }}: {{ $t(status) }}</h4>
    <p class="ma-0">
      <small>
        {{ user.boxUrl }}
        -
        {{ ping }} ms
      </small>
    </p>
  </v-alert>
</template>
<script>
export default {
  computed: {
    user() {
      return this.$store.state.user || {};
    },
    type() {
      switch (this.status) {
        case "connected":
          return "success";
        case "disconnected":
          return "error";
        default:
          return null;
      }
    },
    ping() {
      return this.$store.state.boxStatus.ping;
    },
    status() {
      console.log();
      if (!this.$store.state.boxStatus.queriedAt) return "loading";
      if (this.$store.state.boxStatus.fail) return "disconnected";
      return "connected";
    }
  }
};
</script>
