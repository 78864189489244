import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

export const recordRoutes = [
  {
    path: "datasheet",
    name: "Datasheet",
    meta: {
      icon: "mdi-account-details",
      show: ["user", "tech"]
    },
    component: () =>
      import(
        /* webpackChunkName: "record-datasheet" */ "../views/Datasheet.vue"
      )
  },
  {
    path: "orders",
    name: "Orders",
    meta: {
      icon: "mdi-cart",
      show: ["user", "tech"]
    },
    component: () =>
      import(/* webpackChunkName: "record-datasheet" */ "../views/Orders.vue")
  },
  {
    path: "capture",
    name: "Capture",
    meta: {
      icon: "mdi-camera-iris",
      show: ["user", "tech"]
    },
    component: () =>
      import(/* webpackChunkName: "record-capture" */ "../views/Capture.vue")
  },
  {
    path: "pressure-map",
    name: "PressureMap",
    meta: {
      icon: "mdi-foot-print",
      show: ["user", "tech"]
    },
    component: () =>
      import(
        /* webpackChunkName: "record-pressure-map" */ "../views/PressureMap.vue"
      )
  },
  {
    path: "view-3d",
    name: "View3D",
    meta: {
      icon: "mdi-rotate-3d",
      show: ["user", "tech"]
    },
    component: () =>
      import(/* webpackChunkName: "record-view3d" */ "../views/View3D.vue")
  },
  {
    path: "finalize",
    name: "Finalize",
    meta: {
      icon: "mdi-check-all",
      show: ["user", "tech"]
    },
    component: () =>
      import(/* webpackChunkName: "record-finalize" */ "../views/Finalize.vue")
  }
];

export const routes = [
  { path: "/index.html", redirect: { name: "Home" } },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/Logout.vue")
  },
  {
    path: "/worksheet",
    name: "Worksheet",
    component: () =>
    import(
      "../views/Worksheet.vue"
    )
  },
  {
    path: "/option-editor",
    name: "OptionEditor",
    component: () =>
      import(
        /* webpackChunkName: "option-editor" */ "../views/OptionEditor.vue"
      )
  },
  {
    path: "/cover-editor",
    name: "CoverEditor",
    component: () =>
      import(/* webpackChunkName: "cover-editor" */ "../views/CoverEditor.vue")
  },
  {
    path: "/stats",
    name: "Stats",
    component: () =>
      import( "../views/Stats.vue" )
  },
  {
    path: "/r/:recordId",
    component: () =>
      import(/* webpackChunkName: "record" */ "../views/Record.vue"),
    children: [{ path: "", redirect: { name: "Datasheet" } }, ...recordRoutes]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const cred = !!(
    store.state.user &&
    store.state.user.UserID &&
    store.state.user.UserSecret
  );

  const query = {};
  if (to.name === "Logout") query.redirect = to.query.redirect;
  else query.redirect = to.path;

  if (!cred && to.name !== "Login") return next({ name: "Login", query });
  if (cred && to.name === "Login") return next({ name: "Home" });
  next();
});

export default router;
