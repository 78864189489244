// displays the multilang object as a string.
// precedence: current language -> la -> en

import i18n from "@/i18n";

export default value => {
  const loc = i18n.locale;
  if (typeof value !== "object") return value;
  if (value[loc]) return value[loc];
  if (value["la"]) return value["la"];
  if (value["en"]) return value["en"];
  console.warn(`No 'en' key in ${JSON.stringify(value)}`);
  return Object.values(value)[0];
};
